const list = [
	"AFEB",
	"ALLIANZ",
	"AMIL",
	"AMIL ONLINE",
	"BACEN",
	"BNDES",
	"BRADESCO",
	"BRB SAÚDE",
	"CAESAN",
	"CAPESESP",
	"CASSI",
	"CODEVASF",
	"CONAB",
	"ELETRONORTE (E-VIDA)",
	"EMBRATEL- PAME",
	"FASCAL",
	"FUSEX",
	"GAMA SAÚDE",
	"GDF SAÚDE",
	"INFRAERO",
	"INTERMEDICA",
	"IRB",
	"LIFE EMPRESARIAL SAÚDE",
	"MEDISERVICE",
	"OMINT",
	"PARTICULAR",
	"PLAN ASSISTE",
	"POSTAL SAÚDE",
	"PROASA",
	"REAL GRANDEZA",
	"SAÚDE CAIXA",
	"SERPRO",
	"STF - MED",
	"STJ",
	"STM (PLAS/JMU)",
	"TJDFT",
	"TRE",
	"TRF - PRO SOCIAL",
	"TRT",
	"TST SAÚDE",
	"UNAFISCO (SINDIFISCO)",
	"UNIMED VTRP",
]

export default list;