import React from "react"
import * as styles from "./styles.module.scss"

const index = ({ setStatus, setModalStatus, children}) => {
	const handleClickModal = ({ target, currentTarget }) => {
		if (target === currentTarget) {
			setModalStatus(false)
		}
	}

	return (
		<div
			className={setStatus ? styles.modal : styles.modal_hidden}
			onClick={handleClickModal}
			// onKeyPress={handleClickModal}
			// role="button"
			// tabIndex={0}
		>
			<div className={styles.modal_container}>
				<div
					className={styles.modal_btn_close}
					onClick={handleClickModal}
					// onKeyPress={handleClickModal}
					// role="presentation"
				>
					&times;
				</div>
				<div className={styles.modal_data}>
					{children}
				</div>
			</div>
		</div>
	)
}

export default index
