export const serviceWorkPsic = [
	{
		id: 1,
		title: "Psicologia",
		description: `A clínica Clifali conta com uma equipe de psicólogos especializados que realizam atendimento clínico com crianças, adolescentes, adultos, casal e família. Oferecemos atendimento individualizado para crianças portadoras do Transtorno do Espectro Autista (TEA) e Síndromes Raras, realizando intervenção precoce a partir dos preceitos da Análise Do Comportamento ( ABA) e Modelo Denver. Realizamos também orientações para pais, cuidadores, e educadores.`,
	},
]

export const serviceWorkFono = [
	{
		id: 2,
		title: "O que é FONOAUDIOLOGIA?",
		description: `A fonoaudiologia é a profissão da saúde que atua nas	áreas de promoção, proteção e recuperação da COMUNICAÇÃO HUMANA, no que se refere ao seu desenvolvimento, aperfeiçoamento, distúrbios e diferenças.\n Responsável pela promoção da saúde, avaliação, diagnóstico, orientação e (re)habilitação dos aspectos envolvidos nas funções COGNITIVA, na LINGUAGEM ORAL e ESCRITA, na FALA, na fluência, na voz e audição, nas funções orofaciais e na deglutição de indivíduos de qualquer idade.`,
	},
]
