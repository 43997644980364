import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Modal from "../Modal"
import { serviceWorkPsic, serviceWorkFono } from "./serviceWork"

import * as styles from "./styles.module.scss"

const SectionWorks = () => {
	const [modalStatus, setModalStatus] = React.useState(false)
	const [identify, setIdentify] = React.useState("")
	const modalIdPsic = React.useRef(null)
	const modalIdFono = React.useRef(null)

	const handleClickModalPsic = () => {
		if (modalIdPsic.current.id === "psic") {
			setIdentify("psic")
			console.log("Clicou em Psico")
			setModalStatus(true)
		}
	}

	const handleClickModalFono = () => {
		if (modalIdFono.current.id === "fono") {
			setIdentify("fono")
			console.log("Clicou em Fono")
			setModalStatus(true)
		}
	}

	return (
		<section className={styles.works} id="especialidades">
			<div className="container column">
				<div className={styles.psic_container_work}>
					<div className={styles.description}>
						<h2 className={styles.psic_title}>Psicologia</h2>
						<p className={styles.psic_description}>
							Ciência que estuda o comportamento e a mente humana. Nosso
							objetivo é ajudar as pessoas a compreenderem, de forma
							adequada, seus pensamentos e atitudes, para possibilitar
							uma convivência melhor consigo mesmo e em sociedade,
							trazendo maior qualidade de vida e adequada saúde mental.
						</p>
						<a ref={modalIdPsic} id="psic" onClick={handleClickModalPsic}>
							+ Saiba mais
						</a>

						<div className={styles.bottons}>
							<a
								href="https://linkwhats.app/3dfa70"
								target="_blank"
								rel="noreferrer"
							>
								Fazer pré-agendamento
							</a>
							<a href="#">Conhecer profissionais</a>
						</div>
					</div>

					<div className={styles.imageWrapper}>
						<StaticImage
							src="../../images/psicologia_clifali.jpg"
							alt="image_psic"
							width={560}
							quality={90}
							formats={["auto", "webp", "avif"]}
							layout="constrained"
							placeholder="tracedSVG"
						/>
					</div>
				</div>

				<div className={styles.fono_container_work}>
					<div className={styles.description}>
						<h2 className={styles.fono_title}>Fonoaudiologia</h2>
						<p className={styles.fono_description}>
							Ciência que ajuda no desenvolvimento da comunicação oral e
							escrita, aprimorando a audição, fala, a compreensão e a
							expressão. Em alguns casos, também auxilia a odontologia,
							atuando em tratamentos ortodônticos, protéticos e
							buco-maxilar.
						</p>
						<a onClick={handleClickModalFono} ref={modalIdFono} id="fono">
							+ Saiba mais
						</a>

						<div className={styles.bottons}>
							<a
								href="https://linkwhats.app/3dfa70"
								target="_blank"
								rel="noreferrer"
							>
								Fazer pré-agendamento
							</a>
							<a href="#">Conhecer profissionais</a>
						</div>
					</div>
					<div className={styles.imageWrapper}>
						{
							<StaticImage
								src="../../images/fonoaudiologia-edit.jpg"
								alt="image_psic"
								width={560}
								quality={90}
								formats={["auto", "webp", "avif"]}
								layout="constrained"
								placeholder="tracedSVG"
							/>
						}
					</div>
				</div>
			</div>

			{identify === "psic"
				? serviceWorkPsic.map(item => (
						<Modal
							setStatus={modalStatus}
							setModalStatus={setModalStatus}
							key={item.id}
						>
							<h2>{item.title}</h2>
							<p>{item.description}</p>
						</Modal>
				  ))
				: serviceWorkFono.map(item => (
						<Modal
							setStatus={modalStatus}
							setModalStatus={setModalStatus}
							key={item.id}
						>
							<h2>{item.title}</h2>
							<p>{item.description}</p>
						</Modal>
				  ))}
		</section>
	)
}

export default SectionWorks
