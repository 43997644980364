// extracted by mini-css-extract-plugin
export var blog = "styles-module--blog--9O7kx";
export var title_blog = "styles-module--title_blog--GTv3W";
export var links_container_blog = "styles-module--links_container_blog--ScyWQ";
export var blog_princ = "styles-module--blog_princ--tx4DW";
export var image_blog_princ = "styles-module--image_blog_princ--aVTNt";
export var image = "styles-module--image--adtdH";
export var description_blog_princ = "styles-module--description_blog_princ--rQ6Ns";
export var other_posts_blog = "styles-module--other_posts_blog--6zFv7";
export var image_other_post_blog = "styles-module--image_other_post_blog--CxDX+";
export var title_other_post_blog = "styles-module--title_other_post_blog--Krdwq";