// extracted by mini-css-extract-plugin
export var works = "styles-module--works--jn6xr";
export var psic_container_work = "styles-module--psic_container_work--sMEDr";
export var description = "styles-module--description--MLF0c";
export var psic_title = "styles-module--psic_title--SxfO7";
export var psic_description = "styles-module--psic_description--BTxl2";
export var bottons = "styles-module--bottons--tX6lN";
export var imageWrapper = "styles-module--imageWrapper--Th2Fk";
export var fono_container_work = "styles-module--fono_container_work--U-EMx";
export var fono_title = "styles-module--fono_title--U1co2";
export var fono_description = "styles-module--fono_description--mfxdx";